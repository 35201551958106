import { Link } from "gatsby";
import React from "react";

import coverImage from "../../images/cover.jpg";
import * as styles from "./styles.module.scss";

const Cover: React.FC<{}> = () => {
  return (
    <div className={styles.container}>
      <div className={styles.background}>
        <div className={styles.content}>
          <h1>#dubistmehrwert</h1>
          <h2>Mehr Wertschätzung für Mitarbeiter*innen der Post</h2>
          <Link to='/#form' className={styles.supportLink}>
            Unterstützen
          </Link>
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img src={coverImage} alt='Hands holding' className={styles.image} />
        <SupportButton />
      </div>
    </div>
  );
};

const SupportButton: React.FC<{}> = () => {
  return (
    <Link to='/#form' className={styles.supportButton}>
      <div className={styles.supportButtonContainer}>
        <div className={styles.innerCircle}>
          <svg viewBox='0 0 45 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M44.063 12.7775C44.6488 12.1917 44.6488 11.2419 44.063 10.6561L34.5171 1.11019C33.9313 0.524406 32.9816 0.524406 32.3958 1.11019C31.81 1.69598 31.81 2.64573 32.3958 3.23151L40.8811 11.7168L32.3958 20.2021C31.81 20.7879 31.81 21.7376 32.3958 22.3234C32.9816 22.9092 33.9313 22.9092 34.5171 22.3234L44.063 12.7775ZM0.0023805 13.2168L43.0024 13.2168L43.0024 10.2168L0.00238024 10.2168L0.0023805 13.2168Z'
              fill='white'
            />
          </svg>
        </div>
        <svg viewBox='0 0 159 159' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <circle cx='79.0024' cy='79.7166' r='79' fill='white' />
          <path
            d='M30.8016 59.6156C30.5266 60.3895 30.1275 61.0345 29.6046 61.5505C29.0715 62.0629 28.4274 62.3943 27.6721 62.5447C26.9203 62.6851 26.067 62.5856 25.1122 62.2463L18.5992 59.9318L19.285 58.002L25.8131 60.3219C26.6372 60.6148 27.32 60.6367 27.8613 60.3876C28.4062 60.1284 28.8037 59.6471 29.0537 58.9435C29.3002 58.25 29.2938 57.6308 29.0347 57.0859C28.7755 56.541 28.2339 56.1221 27.4097 55.8292L20.8817 53.5093L21.5674 51.5795L28.0804 53.8941C29.0352 54.2334 29.7564 54.7047 30.2438 55.3081C30.7312 55.9115 31.0164 56.5902 31.0994 57.3442C31.1759 58.0845 31.0766 58.8417 30.8016 59.6156Z'
            fill='#FF9933'
          />
          <path
            d='M33.7766 52.4192L24.4088 46.2804L25.5314 44.5675L35.0142 44.488L28.4166 40.1646L29.5391 38.4516L38.9069 44.5904L37.7844 46.3034L28.3149 46.3916L34.8992 50.7062L33.7766 52.4192Z'
            fill='#FF9933'
          />
          <path
            d='M43.3472 40.2036L36.7715 33.2753L34.4041 35.5223L33.2696 34.327L39.5015 28.4121L40.636 29.6075L38.257 31.8654L44.8327 38.7937L43.3472 40.2036Z'
            fill='#FF9933'
          />
          <path
            d='M50.0973 34.6035L44.3164 25.0108L50.5791 21.2367L51.4297 22.6482L46.9211 25.3652L48.5067 27.9964L52.6042 25.5271L53.4301 26.8975L49.3326 29.3667L51.0008 32.1349L55.5094 29.4179L56.36 30.8294L50.0973 34.6035Z'
            fill='#FF9933'
          />
          <path
            d='M60.1282 29.2414L56.5475 18.6292L60.4437 17.3146C61.2927 17.0282 62.0395 16.9394 62.6842 17.0484C63.3355 17.1438 63.8812 17.3931 64.3212 17.7962C64.7578 18.1893 65.0766 18.684 65.2778 19.2803C65.4961 19.9271 65.5238 20.5764 65.3609 21.228C65.2081 21.8762 64.8138 22.4426 64.1781 22.9273L67.8751 26.6275L65.6465 27.3795L62.2639 23.8602L60.6569 24.4024L62.0687 28.5867L60.1282 29.2414ZM60.1761 22.9774L62.0105 22.3584C62.6573 22.1402 63.0795 21.8232 63.2769 21.4076C63.4744 20.992 63.4862 20.5265 63.3123 20.011C63.1418 19.5057 62.8538 19.1526 62.4483 18.9517C62.0529 18.7473 61.5217 18.7577 60.8546 18.9828L59.0354 19.5966L60.1761 22.9774Z'
            fill='#FF9933'
          />
          <path
            d='M74.9003 25.941C74.0838 26.03 73.3477 25.9706 72.6921 25.7631C72.0353 25.5449 71.4983 25.1956 71.0812 24.7153C70.6629 24.2244 70.4075 23.603 70.3149 22.8513L72.4622 22.6175C72.54 23.1347 72.7735 23.5546 73.1627 23.877C73.5613 24.1877 74.0735 24.309 74.6991 24.2408C75.2399 24.1819 75.6555 24.0079 75.9459 23.7188C76.2352 23.4191 76.3561 23.0518 76.3088 22.617C76.2591 22.1611 76.0773 21.8214 75.7632 21.5981C75.4598 21.3736 75.0662 21.2073 74.5823 21.099C74.0984 20.9908 73.5815 20.8754 73.0317 20.7529C72.1404 20.5495 71.4414 20.2393 70.9346 19.8224C70.4385 19.4043 70.1482 18.8082 70.0639 18.0342C69.9817 17.3779 70.0743 16.7991 70.3416 16.2979C70.6183 15.7849 71.0239 15.3706 71.5582 15.0549C72.0913 14.7286 72.7237 14.5256 73.4554 14.4459C74.1977 14.3651 74.8643 14.4266 75.4551 14.6305C76.0566 14.8333 76.5424 15.1559 76.9125 15.5984C77.2932 16.0398 77.5299 16.5881 77.6227 17.2432L75.4436 17.4805C75.3903 17.0893 75.199 16.7614 74.8697 16.4969C74.5499 16.2205 74.1303 16.1106 73.6107 16.1672C73.1641 16.2051 72.7998 16.3575 72.5177 16.6242C72.245 16.8792 72.1324 17.2241 72.1797 17.6589C72.2201 18.03 72.3691 18.3142 72.6267 18.5115C72.883 18.6982 73.2208 18.8438 73.6399 18.9484C74.059 19.053 74.5376 19.1618 75.0757 19.2749C75.6479 19.4057 76.1758 19.5735 76.6595 19.7784C77.1432 19.9832 77.546 20.2827 77.8679 20.6768C78.1886 21.0604 78.3848 21.5809 78.4564 22.2383C78.5199 22.8215 78.4303 23.3785 78.1877 23.9092C77.9451 24.4399 77.5489 24.8908 76.9991 25.2618C76.4483 25.6223 75.7487 25.8487 74.9003 25.941Z'
            fill='#FF9933'
          />
          <path
            d='M85.0264 25.7983L86.0605 16.3024L82.8156 15.9491L82.994 14.3107L91.5356 15.2409L91.3571 16.8792L88.0964 16.5241L87.0623 26.02L85.0264 25.7983Z'
            fill='#FF9933'
          />
          <path
            d='M97.6633 28.888C96.8869 28.6198 96.2385 28.2265 95.7179 27.708C95.2008 27.1795 94.8637 26.5383 94.7066 25.7844C94.5596 25.0339 94.6516 24.1798 94.9825 23.222L97.2396 16.6889L99.1753 17.3576L96.913 23.9059C96.6274 24.7326 96.6115 25.4155 96.8654 25.9546C97.1293 26.4972 97.6142 26.8904 98.3199 27.1343C99.0156 27.3746 99.6347 27.3628 100.177 27.0988C100.72 26.8349 101.134 26.2895 101.42 25.4628L103.682 18.9146L105.618 19.5834L103.361 26.1165C103.03 27.0742 102.565 27.7995 101.966 28.2922C101.367 28.7849 100.69 29.0761 99.9372 29.1657C99.1976 29.2488 98.4396 29.1562 97.6633 28.888ZM103.13 17.5728C102.868 17.4822 102.677 17.3148 102.558 17.0707C102.44 16.8265 102.426 16.5733 102.516 16.3112C102.607 16.0491 102.774 15.8586 103.018 15.7398C103.262 15.6211 103.516 15.607 103.778 15.6975C104.04 15.7881 104.23 15.9555 104.349 16.1996C104.468 16.4438 104.482 16.6969 104.391 16.9591C104.301 17.2212 104.133 17.4117 103.889 17.5304C103.645 17.6492 103.392 17.6633 103.13 17.5728ZM100.408 16.6323C100.146 16.5418 99.9551 16.3744 99.8363 16.1302C99.7176 15.886 99.7035 15.6329 99.794 15.3708C99.8846 15.1086 100.052 14.9182 100.296 14.7994C100.54 14.6806 100.793 14.6665 101.056 14.7571C101.318 14.8476 101.508 15.015 101.627 15.2592C101.746 15.5033 101.76 15.7565 101.669 16.0186C101.579 16.2808 101.411 16.4712 101.167 16.59C100.923 16.7088 100.67 16.7229 100.408 16.6323Z'
            fill='#FF9933'
          />
          <path
            d='M107.122 33.2505L112.17 25.1414L109.399 23.4164L110.27 22.0173L117.564 26.5581L116.693 27.9572L113.909 26.2237L108.861 34.3328L107.122 33.2505Z'
            fill='#FF9933'
          />
          <path
            d='M114.002 38.1371L115.098 37.0155L124.317 34.9508L120.667 31.384L121.863 30.1594L127.208 35.3811L126.112 36.5027L116.869 38.545L120.6 42.1901L119.404 43.4147L114.002 38.1371Z'
            fill='#FF9933'
          />
          <path
            d='M121.958 46.7282L131.326 40.5895L135.334 46.7053L133.955 47.6086L131.07 43.2057L128.501 44.8895L131.123 48.8909L129.785 49.7679L127.163 45.7665L124.459 47.5379L127.344 51.9408L125.966 52.8441L121.958 46.7282Z'
            fill='#FF9933'
          />
          <path
            d='M127.721 56.4784L138.247 52.6518L138.947 54.5765L133.332 62.2188L140.745 59.5238L141.445 61.4485L130.919 65.2752L130.219 63.3504L135.819 55.7136L128.421 58.4032L127.721 56.4784Z'
            fill='#FF9933'
          />
          <path
            d='M126.749 101.609C127.011 100.83 127.399 100.179 127.913 99.6536C128.437 99.132 129.075 98.7894 129.827 98.6258C130.577 98.4723 131.431 98.5569 132.392 98.8795L138.944 101.08L138.292 103.021L131.725 100.816C130.896 100.537 130.213 100.527 129.676 100.786C129.135 101.055 128.746 101.543 128.509 102.251C128.274 102.948 128.292 103.567 128.56 104.108C128.829 104.648 129.378 105.057 130.207 105.336L136.774 107.541L136.122 109.483L129.57 107.282C128.609 106.96 127.88 106.501 127.382 105.906C126.884 105.311 126.587 104.638 126.491 103.885C126.402 103.146 126.488 102.388 126.749 101.609Z'
            fill='#FF9933'
          />
          <path
            d='M123.999 108.604L133.473 114.578L132.38 116.31L122.9 116.555L129.572 120.763L128.48 122.495L119.006 116.521L120.099 114.788L129.565 114.535L122.907 110.336L123.999 108.604Z'
            fill='#FF9933'
          />
          <path
            d='M114.807 120.753L121.503 127.566L123.831 125.278L124.986 126.453L118.858 132.476L117.703 131.3L120.042 129.001L113.347 122.189L114.807 120.753Z'
            fill='#FF9933'
          />
          <path
            d='M108.345 126.302L114.292 135.792L108.096 139.675L107.221 138.279L111.682 135.483L110.05 132.88L105.997 135.421L105.147 134.065L109.201 131.524L107.485 128.786L103.024 131.581L102.149 130.185L108.345 126.302Z'
            fill='#FF9933'
          />
          <path
            d='M98.6297 131.715L102.395 142.263L98.5224 143.645C97.6785 143.946 96.9333 144.048 96.2869 143.95C95.6339 143.866 95.084 143.627 94.6371 143.231C94.1937 142.846 93.8662 142.357 93.6546 141.764C93.4251 141.121 93.3861 140.472 93.5376 139.818C93.6791 139.167 94.0634 138.594 94.6906 138.098L90.9296 134.463L93.1447 133.672L96.5882 137.132L98.1855 136.562L96.7009 132.403L98.6297 131.715ZM98.6911 137.978L96.8678 138.629C96.2249 138.859 95.8083 139.183 95.6181 139.602C95.4279 140.021 95.4242 140.487 95.6071 140.999C95.7864 141.501 96.0806 141.849 96.4895 142.043C96.8884 142.241 97.4194 142.221 98.0824 141.984L99.8907 141.339L98.6911 137.978Z'
            fill='#FF9933'
          />
          <path
            d='M84.1594 135.199C84.9742 135.096 85.7112 135.142 86.3704 135.338C87.0309 135.545 87.5739 135.885 87.9993 136.358C88.4261 136.841 88.6923 137.458 88.798 138.208L86.6551 138.479C86.5683 137.964 86.3275 137.548 85.9328 137.232C85.5288 136.929 85.0146 136.816 84.3902 136.895C83.8505 136.964 83.438 137.145 83.1527 137.439C82.8688 137.744 82.7542 138.113 82.8092 138.547C82.8668 139.002 83.0545 139.338 83.3724 139.556C83.6797 139.775 84.0762 139.935 84.5619 140.035C85.0476 140.134 85.5664 140.241 86.1183 140.354C87.0129 140.541 87.7173 140.839 88.2312 141.247C88.7346 141.657 89.0352 142.248 89.133 143.02C89.2267 143.675 89.1442 144.255 88.8857 144.761C88.6179 145.279 88.2197 145.7 87.691 146.025C87.1636 146.361 86.5349 146.575 85.8047 146.667C85.0639 146.761 84.3964 146.711 83.802 146.517C83.1971 146.325 82.7058 146.011 82.328 145.575C81.9397 145.14 81.6934 144.596 81.5892 143.943L83.7638 143.668C83.824 144.058 84.021 144.382 84.3548 144.641C84.6793 144.912 85.1009 145.014 85.6194 144.949C86.0652 144.903 86.4268 144.744 86.7042 144.473C86.9724 144.213 87.079 143.866 87.0241 143.432C86.9772 143.062 86.8233 142.78 86.5623 142.588C86.3027 142.405 85.9625 142.266 85.5416 142.168C85.1207 142.071 84.6403 141.971 84.1004 141.867C83.526 141.746 82.9952 141.588 82.508 141.391C82.0208 141.195 81.6129 140.903 81.2841 140.514C80.9567 140.136 80.7515 139.619 80.6685 138.963C80.5948 138.381 80.6746 137.823 80.908 137.288C81.1413 136.753 81.5295 136.295 82.0727 135.915C82.6172 135.545 83.3128 135.306 84.1594 135.199Z'
            fill='#FF9933'
          />
          <path
            d='M74.2894 135.499L73.4212 145.011L76.6717 145.308L76.5219 146.949L67.9655 146.168L68.1153 144.527L71.3817 144.825L72.2499 135.313L74.2894 135.499Z'
            fill='#FF9933'
          />
          <path
            d='M61.8526 132.665C62.6335 132.92 63.2887 133.302 63.8182 133.811C64.3445 134.33 64.6927 134.966 64.8629 135.717C65.023 136.465 64.9459 137.32 64.6318 138.284L62.4891 144.855L60.542 144.22L62.6897 137.634C62.9608 136.802 62.9648 136.119 62.7015 135.584C62.4281 135.046 61.9365 134.662 61.2266 134.43C60.5269 134.202 59.9081 134.225 59.3701 134.498C58.8322 134.771 58.4277 135.324 58.1566 136.155L56.0089 142.742L54.0618 142.107L56.2045 135.536C56.5186 134.572 56.9709 133.839 57.5612 133.336C58.1516 132.833 58.8226 132.53 59.5741 132.427C60.3122 132.331 61.0717 132.411 61.8526 132.665ZM56.5843 144.074C56.848 144.16 57.0413 144.324 57.1644 144.566C57.2874 144.808 57.3059 145.061 57.2199 145.325C57.134 145.588 56.9699 145.782 56.7279 145.905C56.4858 146.028 56.2329 146.046 55.9693 145.96C55.7056 145.874 55.5123 145.71 55.3892 145.468C55.2662 145.226 55.2477 144.973 55.3337 144.71C55.4196 144.446 55.5837 144.253 55.8257 144.13C56.0678 144.007 56.3206 143.988 56.5843 144.074ZM59.3224 144.967C59.5861 145.053 59.7795 145.217 59.9025 145.459C60.0255 145.701 60.044 145.954 59.958 146.218C59.8721 146.481 59.7081 146.675 59.466 146.798C59.2239 146.921 58.9711 146.939 58.7074 146.853C58.4437 146.767 58.2504 146.603 58.1274 146.361C58.0043 146.119 57.9858 145.866 58.0718 145.603C58.1578 145.339 58.3218 145.146 58.5638 145.023C58.8059 144.9 59.0588 144.881 59.3224 144.967Z'
            fill='#FF9933'
          />
          <path
            d='M52.5696 128.565L47.6638 136.761L50.4644 138.437L49.618 139.851L42.2458 135.438L43.0922 134.024L45.9065 135.709L50.8123 127.513L52.5696 128.565Z'
            fill='#FF9933'
          />
          <path
            d='M45.8159 123.939L44.7399 125.08L35.5574 127.305L39.27 130.808L38.0951 132.053L32.6602 126.926L33.7362 125.785L42.9419 123.582L39.1479 120.002L40.3228 118.757L45.8159 123.939Z'
            fill='#FF9933'
          />
          <path
            d='M37.8859 115.671L28.6266 121.972L24.5128 115.927L25.8752 115L28.8368 119.352L31.3765 117.623L28.6849 113.668L30.0077 112.768L32.6992 116.723L35.3712 114.905L32.4096 110.553L33.772 109.626L37.8859 115.671Z'
            fill='#FF9933'
          />
          <path
            d='M32.0881 106.241L21.6305 110.251L20.8972 108.338L26.3778 100.599L19.0126 103.423L18.2794 101.511L28.737 97.5011L29.4703 99.4134L24.0046 107.147L31.3549 104.328L32.0881 106.241Z'
            fill='#FF9933'
          />
        </svg>
      </div>
    </Link>
  );
};

export default Cover;
