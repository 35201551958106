import React from "react";

import * as styles from "./styles.module.scss";
import sperlhofImage from "../../images/prizes/sperlhof.jpg";
import sperlhofLogo from "../../images/prizes/sperlhof-logo.png";
import elgauchoImage from "../../images/prizes/elgaucho.jpg";
import elgauchoLogo from "../../images/prizes/elgaucho-logo.png";
import parkthermeImage from "../../images/prizes/parktherme.jpg";
import parkthermeLogo from "../../images/prizes/parktherme-logo.png";
import Section from "../../components/layout/Section";
import Heading from "../../components/ui/Heading";
import Overview from "../../components/media/Overview";

const PRIZES = [
  {
    description: "Wellness-Wochenende für Zwei im Hotel Sperlhof in Windischgarsten",
    image: sperlhofImage,
    imageAlt: "Frau in pool",
    logo: sperlhofLogo,
    logoAlt: "Sperlhof Logo",
  },
  {
    description: "Steakdinner für Zwei im El Gaucho Wien/Graz/Baden",
    image: elgauchoImage,
    imageAlt: "Steak",
    logo: elgauchoLogo,
    logoAlt: "El Gaucho Logo",
  },
  {
    description:
      "2 Übernachtungen im Vitalhotel für zwei Personen inkl. Halbpension und Eintritte in die Parktherme Bad Radkersburg inkl. Saunadorf",
    image: parkthermeImage,
    imageAlt: "Therme",
    logo: parkthermeLogo,
    logoAlt: "Loipersdorf Logo",
  },
];

const NewsTeaser: React.FC<{}> = () => {
  return (
    <Section id='prizes'>
      <Heading firstHighlightedWord='Neuigkeiten ' heading='über Wertschätzung ' small />
      <Overview category='Neuigkeit' teaser />
    </Section>
  );
};

export default NewsTeaser;
