import React from "react";
import { IInput } from "../../../hooks/useInput";
import * as styles from "./styles.module.scss";

interface IInputFieldProps {
  label: string;
  type?: "password";
  input: IInput;
  errorMessage: string;
}

const InputField: React.FC<IInputFieldProps> = (props) => {
  const transformToLowerCase = (event: React.FormEvent<HTMLInputElement>) => {
    (event.target as HTMLInputElement).value = (
      event.target as HTMLInputElement
    ).value.toLowerCase();
  };

  return (
    <div className={styles.container}>
      <label className={styles.label}>{props.label}</label>
      <input
        className={styles.input}
        type={props.type === "password" ? "password" : "text"}
        value={props.input.input}
        onChange={props.input.onChange}
        onBlur={props.input.onBlur}
      />
      <span className={`${styles.error} ${props.input.error && styles.show}`}>
        {props.errorMessage}
      </span>
    </div>
  );
};

export default InputField;
