// extracted by mini-css-extract-plugin
export var checkboxes = "styles-module--checkboxes--Ziotv";
export var container = "styles-module--container--4yFtM";
export var content = "styles-module--content--Ur51K";
export var floating = "styles-module--floating--mYGFK";
export var form = "styles-module--form--IP31L";
export var heart = "styles-module--heart--94HmG";
export var highlight = "styles-module--highlight--Qmj23";
export var link = "styles-module--link--ENR1m";
export var loader = "styles-module--loader--bleAU";
export var nameContainer = "styles-module--nameContainer--7WPlj";
export var overlay = "styles-module--overlay--vMuKu";
export var spin = "styles-module--spin--uoaZT";
export var supportButton = "styles-module--supportButton--U-YPa";
export var supportImage = "styles-module--supportImage--LV-mg";
export var wiggle = "styles-module--wiggle--avWPR";