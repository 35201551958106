import React from "react";

import * as styles from "./styles.module.scss";

const CheckBox: React.FC<{ id: string; label: React.ReactNode; onClick: () => void }> = (props) => {
  return (
    <div className={styles.container}>
      <input className={styles.checkbox} type='checkbox' id={props.id} onClick={props.onClick} />
      <label className={styles.label} htmlFor={props.id}>
        {props.label}
      </label>
    </div>
  );
};

export default CheckBox;
