import * as React from "react";
import Layout from "../components/layout/Layout";
import ContactBanner from "../sections/ContactBanner";

import Cover from "../sections/Cover";
import Form from "../sections/Form";
import Goal from "../sections/Goal";
import Info from "../sections/Info";
import NewsBanner from "../sections/NewsBanner";
import NewsTeaser from "../sections/NewsTeaser";
import Statements from "../sections/Statements";

const IndexPage = () => {
  return (
    <Layout>
      <Cover />
      <Statements />
      <Goal />
      <Info />
      <NewsTeaser />
      <Form />
      <ContactBanner />
      <NewsBanner />
    </Layout>
  );
};

export default IndexPage;
