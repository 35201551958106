import React, { useState } from "react";
import { debounce } from "../util/helper/general.helper";

type IValidateFunction = (value: string) => boolean;

export interface IInput {
  input: string;
  error: boolean;
  isValid: boolean;
  isVisible: boolean;
  reset: () => void;
  initInput: (value: string) => void;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  onToggle: () => void;
}

const useInput = (validate: IValidateFunction) => {
  const [input, setInput] = useState("");
  const [error, setError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const checkValidity = (value: string) => {
    if (validate(value)) {
      setError(false);
      setIsValid(true);
    } else {
      setError(true);
      setIsValid(false);
    }
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const enteredValue = event.currentTarget.value;
    setInput(enteredValue);

    debounce(() => checkValidity(enteredValue), 1250)();
  };

  const handleBlur = () => {
    checkValidity(input);
  };

  const handleToggle = () => {
    if (isVisible) {
      setIsValid(false);
      setError(false);
      setInput("");
    }

    setIsVisible((prevIsVisible) => !prevIsVisible);
  };

  const reset = () => {
    setInput("");
    setError(false);
    setIsValid(false);
  };

  const initInput = (value: string) => {
    setInput(value);
    setIsValid(true);
  };

  return {
    input,
    error,
    isValid,
    isVisible,
    reset,
    initInput,
    onChange: handleChange,
    onBlur: handleBlur,
    onToggle: handleToggle,
  };
};

export default useInput;
