import React from "react";

import * as styles from "./styles.module.scss";
import questionIcon from "../../images/contact-banner.svg";
import Section from "../../components/layout/Section";

const ContactBanner = () => {
  return (
    <Section id='contactBanner'>
      <div className={styles.container}>
        <img src={questionIcon} alt='Question Mark' />
        <h3>Wie erlebst du Wertschätzung?</h3>
        <p>
          Schreib uns{" "}
          <a className={styles.link} href='mailto:info@dubistmehrwert.at'>
            info@dubistmehrwert.at
          </a>
        </p>
      </div>
    </Section>
  );
};

export default ContactBanner;
