import React from "react";
import ResponsiveContainer from "../../ui/ResponsiveContainer";

import * as styles from "./styles.module.scss";

const Section: React.FC<{ id: string; children: React.ReactNode }> = (props) => {
  return (
    <section id={props.id} className={styles.section}>
      <ResponsiveContainer>{props.children}</ResponsiveContainer>
    </section>
  );
};

export default Section;
