import React, { FormEvent, useState } from "react";

import * as styles from "./styles.module.scss";

import InputField from "../../components/ui/InputField";
import useInput, { IInput } from "../../hooks/useInput";
import CheckBox from "../../components/ui/CheckBox";
import Section from "../../components/layout/Section";
import Heading from "../../components/ui/Heading";
import { Link } from "gatsby";
import { AnimatePresence, motion } from "framer-motion";

const Form = () => {
  // HTTP States
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  // CheckBox States
  const [raffleSelected, setRaffleSelected] = useState(false);
  const [newsletterSelected, setNewsletterSelected] = useState(false);
  const [privacyPolicySelected, setPrivacyPolicySelected] = useState(false);

  // Input Field States
  const firstnameInput: IInput = useInput((value: string) => value.trim().length > 0);
  const lastnameInput: IInput = useInput((value: string) => value.trim().length > 0);

  const emailRegEx = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
  const emailInput: IInput = useInput((value: string) => emailRegEx.test(value));

  // CheckBox Handler
  const raffleSelectHandler = () => {
    setRaffleSelected((prevState) => !prevState);
  };

  const newsletterSelectHandler = () => {
    setNewsletterSelected((prevState) => !prevState);
  };

  const privacyPolicySelectHandler = () => {
    setPrivacyPolicySelected((prevState) => !prevState);
  };

  const submitHandler = (event: FormEvent) => {
    event.preventDefault();

    const formData = JSON.stringify({
      firstname: firstnameInput.input,
      lastname: lastnameInput.input,
      email: emailInput.input,
      raffle: raffleSelected,
      newsletter: newsletterSelected,
      privacyPolicy: privacyPolicySelected,
    });

    console.log(formData);

    setLoading(true);

    fetch("https://dubistmehrwertbackend.azurewebsites.net/api/signupform", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: formData,
    })
      .then((response) => {
        setLoading(false);

        if (response.ok) {
          setSuccess(true);
        }
        // return response.json();
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });

    // Reset
    setTimeout(() => {
      setLoading(false);
      setError(false);
      setSuccess(false);
    }, 8000);
  };

  let isValid =
    firstnameInput.isValid && lastnameInput.isValid && emailInput.isValid && privacyPolicySelected;
  return (
    <Section id='form'>
      <div className={styles.container}>
        <Heading
          heading='Jetzt'
          lastHighlightedWord='unterstützen'
          paragraph='Mit deiner Unterstützung bekommen wir gemeinsam die Kraft und Stärke, um mehr Wertschätzung, Ehrlichkeit, Respekt und Toleranz für alle Mitarbeiter*innen zu erreichen!'
        />
        <SupportImage />
        <form className={styles.form} onSubmit={submitHandler}>
          <div className={styles.nameContainer}>
            <InputField label='Vorname' input={firstnameInput} errorMessage='Ungültiger Name' />
            <InputField label='Nachname' input={lastnameInput} errorMessage='Ungültiger Name' />
          </div>
          <InputField label='Email' input={emailInput} errorMessage='Ungültige Email' />
          <div className={styles.checkboxes}>
            <CheckBox
              id='newsletter'
              label={
                <>
                  Ich möchte über{" "}
                  <strong className={styles.highlight}>Neuigkeiten und Angebote</strong> informiert
                  bleiben
                </>
              }
              onClick={newsletterSelectHandler}
            />
            <CheckBox
              id='privacypolicy'
              label={
                <>
                  Ich erkläre mich mit der Verarbeitung meiner persönlichen Daten gemäß der{" "}
                  <Link to='/privacy' className={styles.link}>
                    Datenschutzerklärung
                  </Link>{" "}
                  einverstanden<strong>*</strong>
                </>
              }
              onClick={privacyPolicySelectHandler}
            />
          </div>
          <AnimatePresence>
            {(success || error) && (
              <motion.div
                className={styles.overlay}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <div className={styles.content}>
                  {success && <Success raffleSelected={raffleSelected} />}
                  {error && <Error />}
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          <button className={styles.supportButton} onClick={submitHandler} disabled={!isValid}>
            {!loading && <span>Unterstützen</span>}
            {loading && (
              <>
                <div className={styles.loader}></div>
                <span>Laden</span>
              </>
            )}
          </button>
        </form>
      </div>
    </Section>
  );
};

export default Form;

const Success: React.FC<{ raffleSelected: boolean }> = (props) => {
  return (
    <>
      <svg
        width='392'
        height='372'
        viewBox='0 0 392 372'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M101.246 0.602737C69.1652 3.62223 41.612 19.4966 21.176 46.778C10.4296 61.1161 3.32983 79.1095 0.828191 98.3389C0.268193 103.854 -0.00817534 109.394 0.000184074 114.937C0.000184074 151.983 13.7416 184.756 45.6111 223.762L49.9801 229.095L26.9545 287.684C14.2877 319.892 4.14022 346.343 4.40448 346.467C4.66874 346.573 32.6977 338.521 66.6812 328.562L128.465 310.462L132.834 314.612C142.153 323.476 164.686 344.224 179.731 357.785L195.516 372L202.985 365.449L221.448 349.31C234.027 338.309 250.129 323.194 277.7 296.477C365.187 211.702 392 169.111 392 114.937C392 81.9877 381.43 55.8011 359.074 33.4463C340.505 14.8703 320.774 4.87593 295.387 1.16779C286.068 -0.191865 266.09 -0.0152867 257.775 1.50329C237.762 5.1408 217.819 14.9939 201.312 29.4027C199.643 30.9447 197.878 32.3779 196.027 33.6935C193.944 32.1399 191.945 30.4778 190.037 28.714C165.32 6.9419 135.194 -2.59333 101.246 0.602737ZM123.091 35.8478C132.023 36.8543 139.74 38.9556 146.698 42.2576C161.62 49.3737 171.891 58.5204 186.09 77.3967C193.155 86.7906 194.934 88.7683 195.639 87.9914C196.115 87.4616 199.814 83.0648 203.831 78.2089C220.743 57.8494 232.018 48.2789 247.081 41.4983C260.593 35.424 277.929 33.7465 294.577 36.9072C309.922 39.8384 322.166 46.4425 333.775 58.0966C343.704 67.7725 350.817 79.9748 354.352 93.3947C356.096 99.9104 356.255 101.553 356.29 114.584C356.343 130.494 355.515 136.109 351.251 148.487C344.892 166.922 330.305 188.871 305.218 217.706C290.525 234.587 225.342 298.897 201.488 320.051L196.027 324.889L190.812 320.21C177.288 307.92 163.922 295.459 150.715 282.828L138.154 270.821L101.563 281.539C81.4268 287.437 64.4791 292.451 63.8977 292.663C62.6469 293.158 61.995 294.888 78.3966 253.233L90.5349 222.367L84.5626 215.446C52.9398 178.753 39.5684 154.614 36.0097 127.88C34.8293 119.034 35.5693 101.341 37.4191 94.2776C41.4534 78.774 48.1655 67.3847 59.7577 56.3132C76.6525 40.1916 98.6035 33.0932 123.091 35.8478'
          fill='#F39638'
        />
      </svg>
      <h3>Dankeschön!</h3>
      <p>
        Wir bedanken uns für Deine Unterstützung!{" "}
        {props.raffleSelected && (
          <>Und drücken Dir fest die Daumen, dass Du einen der 3 attraktiven Preise gewinnst.</>
        )}
      </p>
    </>
  );
};

const Error = () => {
  return (
    <>
      <svg
        width='319'
        height='319'
        viewBox='0 0 319 319'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx='159.5' cy='159.5' r='145' fill='#FFDFA2' stroke='#F5C23C' stroke-width='29' />
        <path
          d='M159.5 222.568C155.506 222.568 152.063 221.122 149.173 218.232C146.282 215.341 144.837 211.899 144.837 207.904C144.837 203.805 146.282 200.336 149.173 197.498C152.063 194.66 155.558 193.241 159.658 193.241C163.652 193.241 167.068 194.686 169.906 197.577C172.744 200.468 174.163 203.91 174.163 207.904C174.163 212.004 172.744 215.472 169.906 218.31C167.068 221.149 163.6 222.568 159.5 222.568ZM146.729 177.474V97.2207H172.271V177.474H146.729Z'
          fill='#F5C23C'
        />
      </svg>
      <h3>Oh, da ist etwas schiefgelaufen!</h3>
      <p>Bitte versuche es später erneut. Danke für deine Geduld!</p>
    </>
  );
};

const SupportImage: React.FC = () => {
  return (
    <div className={styles.supportImage}>
      <svg
        width='147'
        height='148'
        viewBox='0 0 147 148'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle
          cx='73.4847'
          cy='74.4818'
          r='60'
          transform='rotate(-15 73.4847 74.4818)'
          fill='#FF9933'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M66.1255 93.321L82.6343 93.6005L96.847 84.7193L96.5036 83.4375C96.0544 81.7612 95.2376 80.5533 94.053 79.8139C92.8685 79.0744 91.586 78.8896 90.2056 79.2595L84.5851 80.7655C84.2152 79.3851 83.6271 78.4065 82.8209 77.8299C82.0147 77.2533 81.1238 76.9196 80.1483 76.8287L66.3079 75.4643L51.0735 79.5464L56.2784 98.9716L66.8785 96.1313L66.1255 93.321ZM63.0784 93.979L58.444 95.2208L54.8243 81.7119L59.4587 80.4701L63.0784 93.979ZM93.209 83.3691L81.618 90.5966L65.2932 90.215L62.4661 79.6643L66.6076 78.5546L79.9152 79.9031C80.3208 79.9706 80.6482 80.1735 80.8974 80.5118C81.1466 80.8502 81.324 81.2166 81.4297 81.611C79.8192 82.0426 78.6309 82.3081 77.865 82.4077C77.099 82.5072 76.2699 82.5356 75.3778 82.4928L71.4616 82.2739L71.1432 85.4241L75.467 85.5866C76.2846 85.6141 77.1301 85.5813 78.0034 85.4882C78.8768 85.3951 79.8065 85.2165 80.7926 84.9523L90.9982 82.2177C91.3926 82.112 91.7685 82.1346 92.126 82.2854C92.4835 82.4362 92.8445 82.7974 93.209 83.3691Z'
          fill='white'
        />
        <path
          className={styles.heart}
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M71.1477 67.2981C74.1179 69.1796 77.5176 71.0869 81.347 73.02C83.6969 69.4312 85.6875 66.0796 87.319 62.9651C88.9504 59.8506 89.4755 57.2087 88.8942 55.0394C88.4363 53.3302 87.478 52.0546 86.0193 51.2124C84.5607 50.3703 82.9768 50.1782 81.2676 50.6362C80.2816 50.9004 79.3764 51.4336 78.5521 52.2357C77.7278 53.0378 77.061 54.0003 76.5516 55.1232C75.549 54.4055 74.4903 53.9053 73.3754 53.6228C72.2604 53.3403 71.2099 53.3311 70.2239 53.5953C68.5147 54.0533 67.2391 55.0116 66.3969 56.4702C65.5548 57.9289 65.3627 59.5128 65.8207 61.2219C66.4019 63.3912 68.1776 65.4166 71.1477 67.2981ZM84.5948 61.4756C83.3395 63.8905 81.8914 66.3745 80.2506 68.9279C77.5528 67.5371 75.0567 66.1098 72.7622 64.6462C70.4677 63.1825 69.1399 61.7769 68.7788 60.4293C68.541 59.5419 68.6327 58.7335 69.0537 58.0042C69.4748 57.2748 70.1291 56.7913 71.0165 56.5535C71.5753 56.4038 72.1611 56.3877 72.7738 56.5054C73.3866 56.623 74.0693 56.9685 74.8218 57.5419L77.767 59.659L79.259 56.3529C79.624 55.4801 80.0424 54.8396 80.5143 54.4313C80.9862 54.023 81.5015 53.744 82.0603 53.5943C82.9477 53.3565 83.7561 53.4482 84.4854 53.8693C85.2147 54.2903 85.6983 54.9446 85.9361 55.832C86.2972 57.1796 85.8501 59.0608 84.5948 61.4756Z'
          fill='white'
        />
      </svg>
    </div>
  );
};
