import { Link } from "gatsby";
import React from "react";
import Section from "../../components/layout/Section";
import ResponsiveContainer from "../../components/ui/ResponsiveContainer";

import * as styles from "./styles.module.scss";

const NewsBanner = () => {
  return (
    <div className={styles.background}>
      <ResponsiveContainer>
        <div className={styles.container}>
          <div className={styles.text}>
            <span>Lust auf mehr?</span>
            <p>Neuigkeiten rund um die Initiative.</p>
          </div>
          <div className={styles.linkContainer}>
            <Link to='news' className={styles.newsLink}>
              Neuigkeiten
            </Link>
          </div>
        </div>
      </ResponsiveContainer>
    </div>
  );
};

export default NewsBanner;
