import React from "react";
import Section from "../../components/layout/Section";
import Heading from "../../components/ui/Heading";

import mitarbeiterImage1 from "../../images/statements/mitarbeiter-1.png";
import mitarbeiterImage2 from "../../images/statements/mitarbeiter-2.png";
import mitarbeiterImage3 from "../../images/statements/mitarbeiter-3.png";

import quotesImage from "../../images/statements/quotes.svg";

import * as styles from "./styles.module.scss";

const Statements: React.FC<{}> = () => {
  return (
    <Section id='statements'>
      <Heading heading='Mitarbeiter*innen über' lastHighlightedWord='Wertschätzung' />
      <div className={styles.statements}>
        <div className={styles.statement}>
          <div className={styles.imageContainer}>
            <img src={mitarbeiterImage1} alt='Post Uniform' className={styles.image} />
            <img src={quotesImage} alt='Quotes Icon' className={styles.quotes} />
          </div>
          <p>
            „Ich muss meine Kinder von der Nachmittagsbetreuung abholen - Derzeit weiß ich oft in
            der Früh nicht, wann ich am Nachmittag mit der Arbeit fertig bin und die Kinder holen
            kann. Mein Urlaub soll mir auch so gegeben werden, wie ich ihn für die Kinderbetreuung
            im Sommer brauche. Ich wünsche mir mehr Entgegenkommen und Toleranz!“
          </p>
          <span>
            Sabine
            <br />
            Zustellerin
          </span>
        </div>
        <div className={styles.statement}>
          <div className={styles.imageContainer}>
            <img src={mitarbeiterImage3} alt='Post Uniform' className={styles.image} />
            <img src={quotesImage} alt='Quotes Icon' className={styles.quotes} />
          </div>
          <p>
            „Ich will von meinem Vorgesetzten so behandelt werden, wie er selbst behandelt werden
            möchte. Das heißt für mich stets Bitte und Danke und nicht: Du musst! Die letzten 2 ½
            Jahre in der Coronazeit haben uns alles abverlangt - jetzt verlangen wir Wertschätzung
            und Menschlichkeit“
          </p>
          <span>
            Andy
            <br />
            Filialmitarbeiter
          </span>
        </div>
        <div className={styles.statement}>
          <div className={styles.imageContainer}>
            <img src={mitarbeiterImage2} alt='Post Uniform' className={styles.image} />
            <img src={quotesImage} alt='Quotes Icon' className={styles.quotes} />
          </div>
          <p>
            „Ich erwarte mir, dass unsere Obrigkeiten meine Meinung und auch mein Arbeitstempo
            akzeptieren sowie meine harte Arbeit entsprechend anerkennen - Das muss sich auch beim
            Einkommen speziell bei den jetzigen Preiserhöhungen deutlich bemerkbar machen. Ehrlichen
            und respektvollen Umgang auf Augenhöhe…“
          </p>
          <span>
            Johann
            <br />
            Logistikmitarbeiter
          </span>
        </div>
      </div>
    </Section>
  );
};

export default Statements;
