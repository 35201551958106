import React from "react";

import * as styles from "./styles.module.scss";

import logoImage from "../../images/logo.svg";
import Section from "../../components/layout/Section";
import Heading from "../../components/ui/Heading";
import LogoHeart from "../../images/logo-heart.svg";
import wertImage from "../../images/wert-grafik.svg";

const Info: React.FC<{}> = () => {
  return (
    <Section id='info'>
      <div className={styles.heading}>
        <img className={styles.logo} src={logoImage} alt='dubistmehrwert Logo' />
        <Heading heading='Wertschätzung von Herzen!' />
      </div>
      <div className={styles.container}>
        <p>
          Die Postmitarbeiter*innen leisten Unglaubliches - von der täglichen Bearbeitung der
          riesigen Paketberge bis zur Bewältigung des enormen Arbeits- und Zeitdrucks. Alles hat
          sich extrem gesteigert -{" "}
          <strong>
            Nun ist es höchst an der Zeit, dass sich auch die Wertschätzung für unsere
            Mitarbeiter*innen proportional steigert.
          </strong>
          <br />
          <br />
          Damit ist nicht das kleine Lob ab und zu oder gelegentlich eine Belohnung gemeint. Nein!
          Diese <strong>Wertschätzung ist mehr</strong> - es ist eine Herzens- und Geisteshaltung,
          die immer den <strong>Menschen</strong> sieht und nicht nur dessen Ertrag bzw. Leistung.
          <br />
          <br />
          Echt gelebte Wertschätzung von Herzen sieht unsere Mitarbeiter*innen als Menschen, die
          aufgrund ihrer <strong>Fähigkeiten, Persönlichkeit und Einzigartigkeit</strong> schon{" "}
          <strong>wertvoll</strong> sind - sie nimmt damit speziell Bezug auf den WERT des
          individuellen Menschen.
          <br />
          <br />
        </p>
        <p>
          Auf Wunsch und Verlangen vieler Mitarbeiter*innen haben die beiden Laienrichter &
          Postgewerkschafter, <strong>Raimund Taschner</strong> und{" "}
          <strong>Anton „Max“ Pacher</strong>, gemeinsam mit zahlreichen Kolleg*innen, die{" "}
          <strong className={styles.highlight}>unabhängige</strong>,{" "}
          <strong className={styles.highlight}>überparteiliche</strong>{" "}
          <strong>Initiative #dubistmehrwert</strong> für mehr
          <img src={wertImage} alt='' className={styles.wertGraphic} />
          ins Leben gerufen!
          <br />
          <br />
          Die Initiative wird weiters von besonderen Menschen, bemerkenswerten Institutionen, mit
          Wertschätzung von Herzen unterstützt.
        </p>
        <img src={LogoHeart} className={styles.heart} alt='Heart Icon' />
      </div>
      <div className={styles.signContainer}>
        <div className={styles.sign}>
          <p>
            Mehr Wertschätzung
            <br />
            =
            <br />
            Mehr Personal
            <br />
            =
            <br />
            Gewinn für Mitarbeiter & Unternehmen
          </p>
        </div>
      </div>
    </Section>
  );
};

export default Info;
