import React from "react";
import Section from "../../components/layout/Section";
import Heading from "../../components/ui/Heading";

import * as styles from "./styles.module.scss";
import handWithHeartIcon from "../../images/goal/hand-with-heart.svg";
import handshakeIcon from "../../images/goal/handshake.svg";
import starsIcon from "../../images/goal/stars.svg";
import smileyIcon from "../../images/goal/smiley.svg";

const Goal = () => {
  return (
    <Section id='goal'>
      <Heading
        heading=''
        lastHighlightedWord='Zielsetzung'
        paragraph='Wir wollen mehr Wertschätzung für unsere Mitarbeiter*innen im Unternehmen spürbar machen durch:'
      />
      <div className={styles.benefits}>
        <div className={styles.benefit}>
          <img src={handshakeIcon} alt='Handshake Icon' />
          <span>Respektvollen und menschlichen Umgang</span>
        </div>
        <div className={styles.benefit}>
          <img src={smileyIcon} alt='Smiley Icon' />
          <span>Weniger Arbeitszeit - Mehr Freizeit</span>
        </div>
        <div className={styles.benefit}>
          <img src={handWithHeartIcon} alt='Hand with Heart Icon' />
          <span>Höheres Einkommen</span>
        </div>
        <div className={styles.benefit}>
          <img src={starsIcon} alt='Stars Icon' />
          <span>Mehr Personal</span>
        </div>
      </div>
    </Section>
  );
};

export default Goal;
